/**
 * @file 管理切换路由时 需要设置的状态
 */
// eslint-disable-next-line
// @ts-nocheck

// import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { pinia } from '@/store';
import { getOriginPath } from '@/lang/i18n';
import { useConfigStore } from '@/store/config';
import { useLayoutStore } from '@/store/layout';
import { useTokenStore } from '@/store/token';
import { supportLang, i18n } from '@/lang/i18n';

const { t } = i18n.global;

export default {
  beforeEach: [
    async({ matched: toMatched, meta, path, params }: RouteLocationNormalized, { matched: fromMatched, path: fromPath }: RouteLocationNormalized, next: NavigationGuardNext):void => {
      // ssr 状态预加载
      if (import.meta.env.SSR) {
        const preloadList = meta?.ssg?.preload || [];
        for (const preload of preloadList) {
          await preload();
        }
      }
      
      next();
    }
  ],
  afterEach: [
    ({ meta, path }, from) => {
      const layoutStore = useLayoutStore(pinia);
      const defaultKeywords = 'HashKey, HSK, Crypto, Exchange, Cryptocurrency, BTC, ETH, USDT, USDC, SFC, Vritual Assest, Tokens, Ethereum, USD, HKD, 比特币, 以太坊, 虚拟币, 数字货币, 交易所';
      const defaultDescription = 'Welcome to the HashKey Global, where you can conveniently trade between fiat and cryptocurrencies. As a highly recognized digital asset trading platform, we provide a safe and secure trading environment based on licensing compliance. Through HashKey Global, you will enjoy a fast, secure and convenient trading experience.';
      // 初始化route布局及对应参数
      layoutStore.initRouteLayout(meta);
      // 页面Title
      layoutStore.pageTitle = meta?.title;

      // 获取原始路由path(剔除语言)
      var oriPath = `/${path.split('/').slice(2).join('/')}`;

      // 改写canonical和alternate的url
      const hostUrl = 'https://www.hashkey.com';
      const langs = ['zh-CN', 'zh-TW', 'en-US'];
      
      const canonicalDom = document.querySelector('link[rel="canonical"]');
      canonicalDom?.setAttribute('href', `${hostUrl}${oriPath}`);
      langs.map(lang => {
        const langDom = document.querySelector(`link[hreflang=${lang}]`);
        if (langDom) {
          langDom.setAttribute('href', `${hostUrl}/${lang}${oriPath}`);
        }
      });
      
      // common.isLoading = false;
      if (!meta.theme) {
        document.documentElement.setAttribute('class', 'light');
      }

      const keywordsMeta = document.querySelector('meta[name="keywords"]');
      const descriptionMeta = document.querySelector('meta[name="description"]');

      // og和twitter
      const ogTitle = document.querySelector('meta[property="og:title"]');
      const ogDesc = document.querySelector('meta[property="og:description"]');
      const ogUrl = document.querySelector('meta[property="og:url"]');

      const twitterSite = document.querySelector('meta[property="twitter:site"]');
      const twitterTitle = document.querySelector('meta[property="twitter:title"]');
      const twitterDesc = document.querySelector('meta[property="twitter:description"]');

      ogUrl?.setAttribute('content', `${hostUrl}${path}`);
      twitterSite?.setAttribute('content', `${hostUrl}${path}`);

      if (meta?.title) {
        ogTitle?.setAttribute('content', `${t(meta.title)}`);
        twitterTitle?.setAttribute('content', `${t(meta.title)}`);
      }

      if (meta.keywords) {
        keywordsMeta?.setAttribute('content', `${t(meta.keywords)}`);
      } else {
        if (!from.meta.keywords) {
          keywordsMeta?.setAttribute('content', defaultKeywords);
        }
      }

      if (meta.description) {
        descriptionMeta?.setAttribute('content', `${t(meta.description)}`);
        ogDesc?.setAttribute('content', `${t(meta.description)}`);
        twitterDesc?.setAttribute('content', `${t(meta.description)}`);
      } else {
        if (!from.meta.description) {
          descriptionMeta?.setAttribute('content', defaultDescription);
        }
      }

      if (!import.meta.env.SSR) {
        // 滚动条重置
        document.documentElement.scrollTo(0, 0);
        document.body.scrollTo(0, 0);
      }
    }
  ]
};
