/**
 * @file 页面配置整合（如何后期接口改成是配置的话）
 */
import { isClient } from '@vueuse/shared';
import { $api, ip } from '@/config/api';
import { i18n, getLanguage } from '@/lang/i18n';
import { useUserStore } from './User';
import { pinia } from './index';

const userStore = useUserStore(pinia);
//  interface Children {
//    blank: boolean;
//    link: string;
//    nofollow: boolean;
//    text: string;
//  }
//  interface Header {
//    blank: boolean;
//    children: Children[];
//    link: string;
//    nofollow: boolean;
//    status: boolean;
//    system: boolean;
//    text: string;
//  }

export const useConfigStore = defineStore('config', {
  state: () => ({
    // 计价货币
    // quoteTokens: <Array<any>>[],
    orgId: 9001,
    realtimeInterval: '24h',
    // 最新成交展示条数
    lastTradesMax: 50,
    // 全局配置
    config: <Record<string, any>>{},
    // 首页配置数据
    // homeConfig: <Record<string, any>>{
    //   announcements: []
    // },
    theme: isClient ? (localStorage.getItem('theme') || 'wh-theme') : 'wh-theme',
    // 是否已请求配置
    isFetchConfig: false,
    homeConfigReady: <any>{},
    localeHomeConfig: <any>{},
    ipBlack: <any>{}
  }),
  getters: {
    // 计价货币
    quoteTokens(state) {
      return state.config?.customQuoteToken || [];
    },
    homeConfig(state) {
      return state.localeHomeConfig[getLanguage().toLowerCase()] || { announcements: [] };
    }
  },
  actions: {
    async getIpBlack() {
      const res: any = await ip.ipBlackList();
      if (res.success) {
        this.ipBlack = res.data;
      }
    },
    // 获取配置
    async getConfig() {
      const res: any = await $api.getConfig();

      if (res.success) {
        this.config = res.data;
        if (res?.data?.logoutFreeTime?.web) userStore.changeOutTime(res?.data?.logoutFreeTime?.web);
      }
    },
    // 首页配置数据
    async getHomeConfig(lang?: string) {
      const res: any = await $api.getIndex_config(lang);
      if (res.code === 200) {
        if (lang) {
          this.homeConfigReady[lang] = true;
          this.localeHomeConfig[lang] = res.data;
        }
        // this.homeConfig = res.data;
        // 接入zendesk
        if (res?.data?.zendesk) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = res.data.zendesk;
          script.id = 'ze-snippet';
          script.onload = () => {
            // this.replaceZendeskIcon();
            try {
              var bindClick = setInterval(() => {
                var zendeskIcon = document.getElementsByTagName('iframe')[1]?.contentWindow?.document?.getElementsByClassName('wrapper-AtBcr')[0];
                if (zendeskIcon) {
                  zendeskIcon.addEventListener('click', function() {
                    gtag('event', 'web_app_view_home_cschat');
                  });
                  clearInterval(bindClick);
                }
              }, 1000);
            } catch (e) {}
          };
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
    },
    replaceZendeskIcon() {
      // if (!window.zE) {
      //   setTimeout(() => { this.replaceZendeskIcon(); }, 1000);
      //   return;
      // }
      const lang = i18n.global.locale.value;
      // 设置语言 Zendesk 语言支持  https://support.zendesk.com/hc/zh-cn/articles/4408821324826
      if (lang === 'zh-CN') {
        (window as any).zE('messenger:set', 'locale', 'zh');
      } else if (lang === 'tr-TR') {
        (window as any).zE('messenger:set', 'locale', 'tr');
      } else if (lang === 'ru-RU') {
        (window as any).zE('messenger:set', 'locale', 'ru');
      } else if (lang === 'ko-KR') {
        (window as any).zE('messenger:set', 'locale', 'ko');
      } else {
        (window as any).zE('messenger:set', 'locale', 'en');
      }
      const conversationBadge: any = document.querySelector('#conversation-badge');
      const unreadIndicator: any = document.querySelector('#unread-indicator');

      // 引入成功时显示
      if (conversationBadge) {
        conversationBadge.style.display = 'block';
      }

      const populateUnreadIndicator = (count: number) => {
        if (!count) return resetUnreadIndicator();

        unreadIndicator.style.background = '#CC3333';
        unreadIndicator.innerHTML = count || '';
        conversationBadge.setAttribute('class', 'tilt-animation');

        if (count <= 0) {
          unreadIndicator.style.display = 'none';
        } else {
          unreadIndicator.style.display = 'block';
        }
      };

      const resetUnreadIndicator = () => {
        unreadIndicator.style.background = 'none';
        unreadIndicator.innerHTML = '';
        conversationBadge.setAttribute('class', '');
      };

      // unread Message on listener
      (window as any).zE('messenger:on', 'unreadMessages', (count: number) => {
        populateUnreadIndicator(count);
      });

      // on page load always close widget
      (window as any).zE('messenger', 'close');

      if (conversationBadge) {
        conversationBadge.onclick = () => {
          // open widget
          (window as any).zE('messenger', 'open');
          // reset unread indicator
          resetUnreadIndicator();
        };
      }
    }
  }
});
