// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
import { pinia } from '@/store';
import { useCommonStore } from '@/store/common';
import { isPC } from '@/utils/tools';

const { t } = i18n.global;
const commonStore = useCommonStore(pinia);

// 通用路由 10个
const common = [
  {
    path: '/register',
    component: () => import('@/pages/register/Index.vue'),
    meta: {
      layout: 'login',
      title: t('现在注册 - HashKey - 买币更方便，存币更安心'),
      description: t('立即注册HashKey，开始进行加密货币交易，包括热门数字资产如比特币(BTC)和以太坊(ETH)。加入我们，尽享安全顺畅的交易体验。'),
      ssg: {}
    }
  },
  // {
  //   path: '/register/invite',
  //   component: () => import('@/pages/register/invite/Invite.vue'),
  //   meta: {
  //       // 此页面为h5的被邀请人注册页面
  //       layout: 'h5Default',
  //       title: t('现在注册 - HashKey - 买币更方便，存币更安心'),
  //       description: t('立即注册HashKey，开始进行加密货币交易，包括热门数字资产如比特币(BTC)和以太坊(ETH)。加入我们，尽享安全顺畅的交易体验。'),
  //       ssg: {}
  //   }
  // },
  {
    path: '/login',
    component: () => import('@/pages/login/Index.vue'),
    meta: {
      layout: 'login',
      title: t('现在登录 - HashKey - 买币更方便，存币更安心'),
      description: t('安全登录 HashKey，开始进行数字加密货币交易：比特币(BTC)、以太坊(ETH)等热门加密货币的交易平台。'),
      ssg: {}
    }
  },
  {
    path: '/modify-password',
    component: () => import('@/pages/login/ModifyPassword.vue'),
    meta: {
      layout: 'login',
      title: t('修改密码'),
      ssg: {}
    }
  },
  {
    path: '/reset-password',
    component: () => import('@/pages/reset-password/Layout.vue'),
    meta: {
      layout: 'login',
      title: t('忘记密码'),
      ssg: {}
    }
  },
  {
    path: '/auth',
    component: () => import('@/pages/auth/Index.vue'),
    meta: {
      // layout: 'login',
      layout: 'default',
      title: t('现在登录 - HashKey - 买币更方便，存币更安心'),
      ssg: {}
    }
  }
];

export default common;