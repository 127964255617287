<template>
  <el-input
    ref="input"
    v-bind="$attrs"
  >
    <template v-for="(value,name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
    <slot />
    <template #suffix>
      <i :class="suffixClass" />
    </template>
  </el-input>
</template>

<script lang="ts">
// namespaced components
export default {
  name: 'HkInput'
};
</script>

<script lang="ts" setup>
import { defineProps } from 'vue';
const props = defineProps({
  suffixClass: {
    type: String,
    default: ''
  }
});
const suffixClass = computed(() => {
  return `icon-${props.suffixClass}`;
});

const currentInstance = ref();

// onMounted(() => { 
//   currentInstance.value = getCurrentInstance();
// });

// watch(() => currentInstance.value, () => {
//   if (currentInstance.value) { 
//     const exposeMethods: any = {};
//     const entries = Object.entries(currentInstance.value.$refs.input);
//     for (const [key, value] of entries) { 
//       exposeMethods[key] = value;
//     }
//     defineExpose(exposeMethods);
//   }
// });

</script>

<style lang="scss" scoped>

.icon-search {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url('@/assets/img/hk-svg/search.svg') center/100% 100% no-repeat;
}

$sizeArray: md lg sm !default;

@each $size in $sizeArray {
  @if $size == lg {
    .input-#{$size} {
      &.is-disabled {
        border-radius: 8px;
        color: var(--gray-500);
        background-color: var(--gray-50);
        box-shadow: none;

        :deep(.el-input__wrapper) {
          border: 1px solid var(--gray-300);
        }

        :deep(.el-input__inner) {
          color: var(--gray-500);
        }
      }

      :deep(.el-input__inner) {
        color: var(--gray-900);
      }

      &:deep(.el-input__wrapper) {
        height: 44px;
        border-radius: 8px;
        border: 1px solid var(--gray-300);
        box-shadow: none;

        &.is-focus,
        &:hover {
          box-shadow: 0 0 0 1px var(--gray-700) inset;
        }

        &.is-error {
          box-shadow: 0 0 0 1px var(--error) inset;
        }

        &.is-disabled {
          border: 1px solid var(--primary-500);
          background-color: var(--gray-50);
        }

        .el-input__clear::after {
          width: 12px;
          height: 12px;
        }
      }

    }

  } @else if $size == sm {
    .input-#{$size} {
      &.is-disabled {
        border-radius: 8px;
        color: var(--gray-500);
        background-color: var(--gray-50);
        box-shadow: none;
        :deep(.el-input__wrapper) {
          border: 1px solid var(--gray-300);
        }

        :deep(.el-input__inner) {
          color: var(--gray-500);
        }
      }

      :deep(.el-input__inner) {
        color: var(--gray-900);
      }
      
      &:deep(.el-input__wrapper) {
        height: 36px;
        border-radius: 8px;
        border: 1px solid var(--gray-300);
        box-shadow: none;

        &.is-focus,
        &:hover {
          box-shadow: 0 0 0 1px var(--gray-700) inset;
        }

        &.is-error {
          box-shadow: 0 0 0 1px var(--error) inset;
        }

        .el-input__clear::after {
          width: 12px;
          height: 12px;
        }
      }

    }

  } @else {
    .input-#{$size} {
      &.is-disabled {
        border-radius: 8px;
        color: var(--gray-500);
        background-color: var(--gray-50);
        box-shadow: none;

        :deep(.el-input__wrapper) {
          border: 1px solid var(--gray-300);
        }

        :deep(.el-input__inner) {
          color: var(--gray-500);
        }

      }

      :deep(.el-input__inner) {
        color: var(--gray-900);
      }

      &:deep(.el-input__wrapper) {
        height: 40px;
        border-radius: 8px;
        border: 1px solid var(--gray-300);
        box-shadow: none;

        &.is-focus,
        &:hover {
          box-shadow: 0 0 0 1px var(--gray-700) inset;
        }

        &.is-error {
          box-shadow: 0 0 0 1px var(--error) inset;
        }

        .el-input__clear::after {
          width: 12px;
          height: 12px;
        }
      }

    }

  }
}
:deep(.el-textarea__inner) {
  box-shadow: 0 0 0 1px var(--border-primary) inset;
  border-radius: 8px;

  &:focus {
    box-shadow: 0 0 0 1px var(--gray-700) inset;
  }
}
</style>