import { pinia } from '@/store/index';
import { useUserStore } from '@/store/User';
import { getQueryValue } from '@/utils/tools';

const user = useUserStore(pinia);
const { userinfo } =  user;

const eventTrack = (params: any) => {
  let data: any = params?.data || {};
  const { name } = params; // 埋点事件名
  const browser = navigator.userAgent; // 获取浏览器信息
  const language = localStorage.getItem('lang') || 'en-US'; // 获取语言
  const timestamp = new Date().getTime(); // 获取事件发生的时间和日期
  const device_type = 'web'; // 设备类型，web端写死web
  const url = location.href; // 事件发生页面的url
  const refer_url = document.referrer; // 上级页面url
  const { userId, userType } = userinfo; // 获取用户信息
  // passport中用的是香港站的ga，默认都加上site参数，用于区分香港站，国际站
  if (data?.extend) {
    data.extend = JSON.stringify({ ...data?.extend, site: getQueryValue('passport') || 'global' });
  } else {
    data.extend = JSON.stringify({ site: getQueryValue('passport') || 'global' });
  }
  const eventValue = Object.assign({
    browser,
    language,
    timestamp,
    userId,
    identity: userType,
    device_type,
    url,
    refer_url
  }, data);

  // console.log("eventValue", eventValue);
  gtag('event', name, { ...eventValue });
};

export default eventTrack;
// // 例子
// import eventTrack from "@/utils/eventTracking";
// eventTrack({ name: "事件名", data: {value: 111, extend: {//业务方要求的额外上报数据}}})

