<template>
  <div :class="[`layout-${layoutStore.routeLayout}`, layoutStore.layoutClass?.layout]">
    <el-config-provider :locale="elLocale">
      <router-view
        :key="route.fullPath"
        :class="layoutStore.layoutClass?.header"
        name="header"
        v-bind="headerProps"
      />
      <router-view
        :key="route.fullPath"
        v-bind="layoutStore.routeProps?.default"
      />
      <router-view
        :key="route.fullPath"
        :class="layoutStore.layoutClass?.footer"
        name="footer"
        v-bind="layoutStore.routeProps?.footer"
      />
    </el-config-provider>
  </div>
  <!-- 提示弹窗 -->
  <WarningCfm
    v-if="userStore.activeOutStatus || userStore.loginStatusExpire"
    :visible="userStore.activeOutStatus || userStore.loginStatusExpire"
    :type="userStore.activeOutStatus ? 3 : 1"
    @up-visible="userStore.activeOutStatus ? userStore.changeOutStatus(false) : userStore.changeLoginStatus(false)"
  />
  <!-- 其他设备登录 -->
  <WarningCfmTwo
    :visible="userStore.loginOtherEquipment"
    :type="2"
    @up-visible="userStore.changeLoginOtherEquipment(false)"
  />
  <!-- vpn弹窗提示 -->
  <VpnWarning
    :visible="userStore?.vpnWarningInfo?.visible || false"
    :type="userStore?.vpnWarningInfo?.userLevel"
    :vpn-block-info="userStore?.vpnWarningInfo"
    @up-visible="userStore?.changeVpnWarningInfo({visible: false})"
  />
</template>
<script setup lang="ts">
import WarningCfm from '@c/common/WarningCfm.vue';
import WarningCfmTwo from '@c/common/WarningCfmTwo.vue';
import VpnWarning from '@c/common/VpnWarning.vue';
import { useWindowScroll } from '@vueuse/core';
import { getCurrentLocale } from '@/lang/element';
import { useLayoutStore } from '@/store/layout';
import { getOriginPath, i18n } from '@/lang/i18n';
import { useUserStore } from '@/store/User';
const route = useRoute();
const layoutStore = useLayoutStore();
const userStore = useUserStore();
const { locale } = useI18n();

// 当前element对应语言的语言包
const elLocale = computed(() => getCurrentLocale(locale.value));
// header 主题
const headerTheme = ref('');
// header参数
const headerProps = computed(() => {

  return {
    ...layoutStore.routeProps.header,
    fixed: true,
    border: Object.keys(layoutStore.routeProps?.header || {}).includes('border') ? layoutStore.headerFixed : true
  };
});

const { y } = useWindowScroll();
watch(y, value => {
  if (getOriginPath(route.matched[0].path) === '/') {
    if (value < 499) {
      headerTheme.value = 'dark';
    } else {
      headerTheme.value = 'light';
    }
  }

  layoutStore.headerFixed = value > 10;
});
</script>
