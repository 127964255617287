import Cookies from 'js-cookie';

/**
 * 获取cookie
 *
 * @param {string} name cookie名
 *
 * @return {string} 返回cookie值
 */
export const getCookie = name => {
  return  Cookies.get(name);
};

/**
 * 设置cookie
 *
 * @param {string} name cookie名
 * @param {string} value cookie值
 * @param {string|number} seconds cookie过期时间
 */
export const setCookie = (name, value, seconds) => {
  const expires = new Date(new Date() * 1 + seconds * 1000);

  const env = import.meta.env.MODE;
  let domain = '.hashkeydev.com';
  if (env === 'production') {
    domain = '.hashkey.com';
  }

  Cookies.set(name, value, { expires: new Date(new Date() * 1 + seconds * 1000), domain });
};

/**
 * 移除cookie
 *
 * @param {string} name cookie名
 */
export const removeCookie = name => {
  const env = import.meta.env.MODE;
  let domain = '.hashkeydev.com';
  if (env === 'production') {
    domain = '.hashkey.com';
  }
  Cookies.remove(name,{domain});
};

/**
 * 移除IPQScookie
 *
 * @param {string} name cookie名
 */
export const removeIPQSCookie = name => {
  let domain = `.${location.hostname}`; // ipqs设置的cookie是这样的domain
  Cookies.remove(name,{domain});
};