// eslint-disable-next-line
// @ts-nocheck
import { getOriginPath, i18n } from '@/lang/i18n';
import { toast } from '@/utils/toast';

export default {
  beforeEach: [(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext):void => {
    const { path } = to;
    const { query } = from;

    // 默认路由login
    if (getOriginPath(path) === '/') {
      next({ path: '/login' });
    } else {
      next();
    }

  }]
};
